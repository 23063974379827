import { defineStore } from 'pinia'
import { i18n } from '@/plugins'

export const useAppStore = defineStore('app', {
    state: () => ({
        crumbs: {},
        calculating: false,
        env: import.meta.env.VITE_APP_ENV,
        version: import.meta.env.APP_VERSION,
    }),
    actions: {
        calculateCrumbs(route) {
            const key = route.fullPath

            if (this.crumbs[key]) {
                return this.crumbs[key]
            }
            let crumbs = []
            const parts = route.name?.split('.') ?? []

            let first = true

            while (parts.length > 0) {
                let name = parts.join('.')

                crumbs.unshift({
                    title:
                        first && route.params?.id
                            ? route.params.id
                            : i18n.global.t(name + '.title'),
                    disabled: first,
                    to: { name: name, params: { id: route.params?.id } },
                })
                first = false
                parts.pop()
            }

            const home = i18n.global.t('home.title')
            if (crumbs.filter((c) => c.title === home).length === 0) {
                crumbs.unshift({
                    title: home,
                    disabled: false,
                    to: { name: 'home' },
                })
            }

            this.crumbs[key] = crumbs

            return crumbs
        },
        closeSnackbar() {
            this.snackbar.show = false
        },
    },
})
