export const getPagination = (props) => {
    const { page, itemsPerPage: per_page } = props || {
        page: 1,
        itemsPerPage: 10,
    }
    return { page, per_page }
}

export const shouldMakeRequest = (pagination, meta) => {
    return (
        pagination.page !== meta.page || pagination.per_page !== meta.per_page
    )
}

export const responseData = (response) => {
    return response.data.data
}
export const errorMessage = (error) => {
    console.log(error)
    return error.response.data.message ?? error.message ?? 'An error occurred'
}
export const responsePagination = (response) => {
    if (!response.data.meta) {
        return
    }
    const { current_page, per_page, total, last_page } = response.data.meta
    return { page: current_page, per_page, total, total_pages: last_page }
}

export const valueFromOptions = (options, value, field) => {
    const result = options.find((option) => option.value === value)
    return result ? result[field] : 'n/a'
}

export const dateString = (date) => {
    return date && new Date(date).toISOString().split('T')[0]
}

export function debounce(fn, wait) {
    let timer
    return function (...args) {
        if (timer) {
            clearTimeout(timer) // clear any pre-existing timer
        }
        const context = this // get the current context
        timer = setTimeout(() => {
            fn.apply(context, args) // call the function if time expires
        }, wait)
    }
}
