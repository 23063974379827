import { createRouter, createWebHistory } from 'vue-router'

import AuthRoutes from './AuthRoutes.js'
import NonPublicRoutes from '@/routes/NonPublicRoutes.js'
import ProfileRoutes from '@/routes/ProfileRoutes.js'
import { useAuthStore } from '@/stores/auth/index.js'
import SettingsRoutes from '@/routes/SettingsRoutes.js'

const routes = [
    {
        path: '/',
        component: () => import('@/components/layout/SidebarView.vue'),
        children: [...NonPublicRoutes, ...SettingsRoutes, ...ProfileRoutes],
    },
    ...AuthRoutes,
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

router.beforeEach((to) => {
    const authStore = useAuthStore()

    if (to.meta) {
        if (to.meta.auth && !authStore.authenticated) {
            return { name: 'login' }
        }

        if (to.meta.verified && !authStore.isVerified) {
            return { name: 'verify-email' }
        }

        if (to.meta.admin && !authStore.isAdmin) {
            return { name: 'home' }
        }

        if (to.meta.guest && authStore.authenticated) {
            return { name: 'dashboard' }
        }
    }
})

export default router
