import {RouterView} from "vue-router";

export default [
    {
        path: '',
        name: 'home',
        meta: {auth: true},
        component: () => import('@/views/Tickets/Dashboard.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {auth: true},
        component: () => import('@/views/Tickets/Dashboard.vue')

    },
    {
        path: '/tickets/meet',
        name: 'tickets.meet',
        component: () =>
            import("../views/Tickets/Meet.vue"),
    },
    {
        path: '/tickets/incoming',
        children: [
            {
                path: '',
                name: 'tickets.incoming',

                component: () =>
                    import("../views/Tickets/Incoming/IncomingIndex.vue"),
            },
            {
                path: ':id',
                name: 'tickets.incoming.show',

                component: () =>
                    import("../views/Tickets/Incoming/IncomingSingle.vue"),
            }
        ]
    },
    {
        path: '/tickets',
        meta: {auth: true},
        components: {
            default: RouterView,
            sidebar: () => import("../views/Tickets/Sidebar.vue"),
        },
        children: [
            {
                path: '',
                name: 'tickets',
                component: () =>
                    import("../views/Tickets/Dashboard.vue"),
            },
            {
                path: ':id',
                name: 'tickets.show',
                component: () =>
                    import("../views/Tickets/Show.vue"),

            }
        ]
    },
    {
        path: '/tickets/create',
        meta: {auth: true, verified: true},
        name: 'tickets.create',
        component: () =>
            import("../views/Tickets/Create.vue"),
    },
    {
        path: '/users',
        meta: {auth: true},
        children: [
            {
                path: '',
                name: 'users',
                meta: {auth: true, admin: true},
                components: {
                    default: () =>
                        import("../views/Users/Index.vue"),
                },
            },
            {
                path: ':id',
                meta: {auth: true},
                name: 'users.show',
                component: () =>
                    import("../views/Users/Show.vue"),
            },
        ],
    },
    {
        path: '/debug',
        meta: {auth: true, admin: true},
        children: [
            {
                path: '',
                name: 'debug',
                component: () =>
                    import("../views/Debug/Index.vue"),
            }
        ]
    }
]
