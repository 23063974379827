<template>
  <v-footer color="transparent" app class="py-1 text-body-2 justify-space-between">
<!--    <template v-if="$route">-->
<!--      <bread-crumbs/>-->
<!--    </template>-->
    <bread-crumbs/>
    <v-spacer/>
    <code>&copy;{{ year }} {{ info }}</code>
  </v-footer>
</template>
<script>
import {mapState} from "pinia";
import {useAppStore} from "@/stores/app/index.js";
import {useAuthStore} from "@/stores/auth/index.js";
import BreadCrumbs from "@/components/nav/BreadCrumbs.vue";

export default {
  name: 'ToverFooter',
  components: {BreadCrumbs},
  data() {
    return {
      year: import.meta.env.BUILD_YEAR,
      date: new Date(import.meta.env.BUILD_DATE).toISOString().split('T')[0]
    }
  },
  computed: {
    ...mapState(useAppStore, ['version']),
    ...mapState(useAuthStore, ['settings']),
    info() {
      let info = `Tover B.V. `
      if (this.settings.buildVersion) info += `// v${this.version}`
      if (this.settings.buildDate) info += ` // ${this.date}`
      return info
    }
  },
  watch: {
    settings: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
  }
  }
}
</script>
