<template>
  <v-snackbar
      v-model="snackbar.show"
      multi-line
      location="top"
      :color="snackbar.color"
  >
    {{ snackbar.message }}

    <template v-slot:actions>
      <template v-if="snackbar.button">
        <v-btn :to="snackbar.button.to"  @click="closeSnackbar()">{{ snackbar.button.text }}</v-btn>
      </template>
      <template v-else>
        <v-btn
          variant="text"
          icon="mdi-close"
          @click="closeSnackbar()"
      />
      </template>
    </template>
  </v-snackbar>
</template>
<script>
import {useAlertsStore} from "../../stores/alerts/index.js";
import {mapActions, mapState} from "pinia";

export default {
  computed: {
    ...mapState(useAlertsStore, ['snackbar'])
  },
  methods: {
    ...mapActions(useAlertsStore, ['closeSnackbar'])
  }
}
</script>
