import {RouterView} from "vue-router";

export default [
    {
        path: '/settings',
        meta: {auth: true},
        component:  () => import("../views/AppSettings/Index.vue"),
        children: [
            {
                path: '',
                name: 'settings',
                component: RouterView,
            },
            {
                path: 'tags',
                name: 'settings.tags',
                component: () =>
                    import("../views/AppSettings/Tags/Index.vue"),
            },
            {
                path: 'products',
                name: 'settings.products',
                component: () =>
                    import("../views/AppSettings/Products/Index.vue"),
            },
        ]
    }
]
