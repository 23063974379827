import axios from 'axios'

import { useAuthStore } from '@/stores/auth/index.js'
import { useAlertsStore } from '@/stores/alerts/index.js'
import router from '@/routes/index.js'
import { errorMessage } from '@/stores/helpers.js'

const axiosInstance = axios.create({
    baseURL: '/api/',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withXSRFToken: true,
    withCredentials: true,
})

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        console.log(error)
        useAlertsStore().showSnackbar({
            color: 'error',
            message: errorMessage(error),
        })

        if (error.response.status === 401) {
            useAuthStore().clearUser()
            router.push({ name: 'home' })
        }
        return Promise.reject(error)
    }
)

export default axiosInstance
