import '@mdi/font/css/materialdesignicons.css'
import "@fontsource/source-sans-pro";
import "@fontsource/source-sans-pro/600.css";
import "@fontsource/source-sans-pro/400-italic.css";
import "@fontsource/source-serif-pro";
import "@fontsource/source-serif-pro/700.css";
import "@fontsource/source-serif-pro/600.css";

// import "vuetify/styles";
import '@/styles/main.scss'
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";

const myCustomLightTheme = {
    dark: false,
    colors: {
        background: "#eee",
        surface: "#FFFFFF",
        primary: "#f1be48",
        "primary-lighten": "rgb(239,215,160)",
        secondary: "#081f2c",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
    },
    variables: {
        'tab-slider-color': 'primary',
    }
};

const vuetify = createVuetify({
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
    variables: {
        'settings.$border-radius-root': '0px',
    },
    theme: {
        defaultTheme: "myCustomLightTheme",
        themes: {
            myCustomLightTheme,
        },
    },
    components: {
        ...components,
    },
    directives,
});

export default vuetify;
