import { RouterView } from 'vue-router'

export default [
    {
        path: '/',
        component: RouterView,
        children: [
            {
                path: '/account',

                component: () => import('@/views/Account/Show.vue'),
                children: [
                    {
                        path: '',
                        name: 'account',
                        component: () => import('@/views/Account/UserInfo.vue'),
                    },
                    {
                        path: 'profile',
                        name: 'account.profile',
                        component: () =>
                            import('@/views/Account/UserProfile.vue'),
                    },
                    {
                        path: 'settings',
                        name: 'account.settings',
                        component: () => import('@/views/Account/Settings.vue'),
                    },
                ],
            },
            {
                path: '/account/tickets',
                name: 'account.tickets',
                component: () => import('@/views/Account/Tickets.vue'),
            },
        ],
    },
]
