<template>
  <v-app-bar color="primary" flat density="compact">
    <v-app-bar-title>
      <v-img height="34px"
             src="/images/logo.svg"
             position="left"
             alt="Tover Tickets"/>
    </v-app-bar-title>
    <template v-if="user">
      <v-btn :to="{name: 'tickets.create'}" color="secondary" variant="flat" rounded="xl" class="mr-4" prepend-icon="mdi-plus" exact>{{ $t('tickets.create.title')}}</v-btn>
    </template>
    <v-toolbar-items variant="elevated">

      <template v-if="user">

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
                class="align-self-center cursor-pointer"
                variant="flat"
                append-icon="mdi-chevron-down"
                color="primary"
                v-bind="props"
            >
              {{ user.name }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item :to="{name: 'account'}">
              {{ $t('account.title') }}
            </v-list-item>
            <v-list-item :to="{name: 'account.settings'}">
              {{ $t('account.settings.title') }}
            </v-list-item>
              <v-divider/>
            <v-list-item @click="logout"
            >{{ $t("auth.logout") }}
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-toolbar-items>
  </v-app-bar>
</template>
<script>
import {useAuthStore} from "@/stores/auth/index.js";
import {mapActions, mapState} from "pinia";

export default {
  name: "NavBar",
  computed: {
    ...mapState(useAuthStore, ["user"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["logout"]),
  }
}
</script>
