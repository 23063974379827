import { createApp } from 'vue'

import router from './routes'

import '@mdi/font/css/materialdesignicons.css'

import App from './App.vue'
import { i18n, vuetify, API } from './plugins'
import pinia from './stores'
import ToverDebug from '@/components/ui/ToverDebug.vue'
import ToverLoading from '@/components/ui/ToverLoading.vue'

const app = createApp(App)
app.config.globalProperties.$api = API

pinia.use(({ store }) => {
    store.$api = app.config.globalProperties.$api
})

app.component('ToverDebug', ToverDebug)
app.component('ToverLoading', ToverLoading)
app.use(vuetify).use(router).use(i18n).use(pinia).mount('#app')
