<template>
  <v-app>
    <nav-bar/>
    <router-view></router-view>
    <tover-footer class="flex-grow-0"/>
    <default-snackbar />
  </v-app>
</template>
<script>
import NavBar from "./components/nav/NavBar.vue";
import ToverFooter from "./components/layout/ToverFooter.vue";
import DefaultSnackbar from "@/components/ui/DefaultSnackbar.vue";

export default {
  name: "App",
  components: {DefaultSnackbar, ToverFooter, NavBar},
};
</script>
