<template>
  <transition mode="out-in">
    <template v-if="loading">
      <v-card height="240" flat class="d-flex justify-center align-center">

        <v-progress-circular indeterminate/>
      </v-card>
    </template>
    <template v-else>
      <slot/>
    </template>
  </transition>
</template>
<script>
export default {
  name: "ToverLoading",
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
