import {defineStore} from "pinia";

export const useAlertsStore = defineStore('alerts', {
    state: () => ({
        alerts: [],
        snackbar: {
            show: false,
            color: undefined,
            message: undefined,
            button: undefined
        }
    }),
    actions: {
        showSnackbar({color, message, button}) {
            this.snackbar = {
                show: true,
                color,
                message,
                button
            }
        },
        closeSnackbar() {
            this.snackbar.show = false;
        }
    }
})
