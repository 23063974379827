import { defineStore } from 'pinia'
import { useAlertsStore } from '../alerts/index.js'
import { responseData, errorMessage } from '@/stores/helpers.js'

const storedSettings = localStorage.getItem('settings') ?? '{}'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        loading: false,
        user: window.user ?? undefined,
        settings: {
            debug: false,
            buildDate: false,
            buildVersion: true,
            ...JSON.parse(storedSettings),
        },
    }),
    getters: {
        getSettings: (state) => {
            return state.settings
        },
        authenticated: (state) => {
            return state.user !== undefined
        },
        isAdmin: (state) => {
            return state.user?.is_admin ?? false
        },
        isVerified: (state) => {
            return state.user?.verified ?? false
        },
    },
    actions: {
        login(form) {
            this.loading = true
            this.$api
                .post('/login', form, { baseURL: '/' })
                .then(() => {
                    this.auth = true
                    this.loadUser()
                })
                .catch((error) => {
                    console.log(error)
                    useAlertsStore().showSnackbar({
                        color: 'error',
                        text: errorMessage(error),
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadUser(user = undefined) {
            console.log('user', user)
            if (user) {
                this.user = user
                return
            }
            this.loading = true
            this.$api
                .get('/account')
                .then((response) => {
                    this.user = responseData(response)
                })
                .catch((error) => {
                    console.log(errorMessage(error))
                    this.user = undefined
                })
                .finally(() => {
                    this.loading = false
                })
        },
        clearUser() {
            this.user = undefined
        },
        logout() {
            this.clearSettings()
            this.$api.post('/logout', '', { baseURL: '/' }).then(() => {
                location.replace('/')
            })
        },
        clearSettings() {
            localStorage.removeItem('settings')
        },
        saveSettings() {
            localStorage.setItem('settings', JSON.stringify(this.settings))
        },
    },
})
