<template>
  <v-breadcrumbs :items="breadCrumbs" class="text-body-2 px-0">
    <template v-slot:divider>
      &RightAngleBracket;
    </template>
  </v-breadcrumbs>
</template>
<script>
import {useAppStore} from "@/stores/app/index.js";
import {mapActions} from "pinia";

export default {
  name: "BreadCrumbs",
  computed: {
    breadCrumbs() {
      return this.calculateCrumbs(this.$route)
    },
  },
  methods: {
    ...mapActions(useAppStore, ['calculateCrumbs'])
  }
}
</script>
