<template>
  <v-card :subtitle="title" class="my-3 bg-white text-red border-red" v-if="show">
    <v-card-text class="text-grey-darken-3"><pre><slot></slot></pre></v-card-text>
  </v-card>
</template>
<script>
import {mapState} from "pinia";
import {useAuthStore} from "@/stores/auth/index.js";

export default {
  name: "ToverDebug",
  props: {
    title: {
      type: String,
      required: false,
      default: 'debug'
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isAdmin','settings']),
    show() {
      return this.settings.debug && this.isAdmin
    }
  }
}
</script>
